/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.shouldUpdateScroll = ({
  routerProps: { location },
}) => {

  if (!location.hash) {
    window.scrollTo({
      top: 0,
      behavior: 'instant'
    })
  }
}

exports.onRouteUpdate = () => {
  setRouteChangeForGTM()
}


const setRouteChangeForGTM = () => {
  if (process.env.NODE_ENV !== `production`) return
  if (sessionStorage.getItem('COOKIE_DECLINED')) return

  setTimeout(() => {
    // @ts-ignore
    window.dataLayer?.push({
      event: `gatsby-route-change`
    })
  }, 50)
}